<template>
  <b-container fluid class="p-0">
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
              <h4 class="card-title">
              {{cvCardTitle}}
              </h4>
          </template>
          <b-row>
            <b-col class="container_minheight" md="12" v-if="1">
              <b-table
                hover
                responsive
                :items="items"
                :fields="columns"
                :no-border-collapse="true"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                :current-page="currentPage"
                :per-page="perPage">

                <template v-slot:cell(app_name)="data">
                    <p>{{data.item.app_name}}</p>
                    <button title="Analyse quiz result" class="ml-2 actionIcon btn" @click="gotoAnalyseQuiz(data.item.app_id)"><i class="fa-solid fa-file-circle-question font-size-20 pointer"></i></button>
                    <button title="View Quiz Details" class="ml-2 actionIcon btn" @click="quizView(data.item.app_id)"><i class="fa-solid fa-eye font-size-20 pointer"></i></button>
                </template>

                <template v-slot:cell(percentage)="data">
                    <p>{{Math.round((data.item.correct_answers / data.item.attempted_questions) * 100) }} %</p>
                </template>
              </b-table>
            </b-col>
            <b-col class="container_minheight" md="12" v-else>
                No data found
            </b-col>
          </b-row>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { QuizAnswers } from "../FackApi/api/quizAnswers.js"

export default {
  name: "QuizReportList",
  data () {
    return {
      cvCardTitle: "Quiz Report",
      items: [],
      columns: [
        { label: "Quiz", key: "app_name", class: "text-left align-text-top", sortable: true },
        { label: "Attempted Questions", key: "attempted_questions", class: "text-left align-text-top pointer", sortable: true },
        { label: "Correct Answers", key: "correct_answers", class: "text-left align-text-top", sortable: true },
        { label: "Percentage", key: "Percentage", class: "text-left align-text-top", sortable: true }
      ],
      appId: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      sortDesc: false,
      sortBy: "name"
    }
  },
  mounted () {
    this.appId = this.$route.params.appId
    this.getQuizReportList()
  },
  methods: {
    /**
     * getQuizReportList
     */
    async getQuizReportList () {
      const payload = {
        app_id: this.appId
      }

      const answerExplanationResp = await QuizAnswers.quizReportList(this, payload)
      if (answerExplanationResp.resp_status) {
        this.items = answerExplanationResp.resp_data.data
      }
    },
    /**
     * gotoAnalyseQuiz
     */
    gotoAnalyseQuiz (appId) {
      this.$router.push(`/analyse_app_result/${appId}`)
    },
    /**
     * quizView
     */
    quizView (appId) {
      try {
        const path = "/quiz_detail/" + appId
        this.$router.push(path)
      }
      catch (err) {
        console.error("Exception occurred at quizView() and Exception:", err.message)
      }
    }
  }
}
</script>
